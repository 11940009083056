import './styles.scss';
import Icon from 'components/atoms/Icon';
import { HeadingWithIcon } from 'components/molecules/HeadingWithIcon';
import AppBar from 'components/organisms/AppBar/AppBar';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import {
  TechnicalSkillSelfAssessmentProficiencyLevel,
  useSkillsProfileOpenOneQuery,
} from 'generated/graphql';
import { technicalSkillProficiencyLevelToBarChartValuesMap } from 'helpers/skillsProficiency';
import { format } from 'helpers/time';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { mapSkills } from './helpers/helpers';
import LastUpdatedLayout from './layouts/lastUpdated/LastUpdated';
import LevelLayout from './layouts/level/Level';
import TypeLayout from './layouts/type/Type';
import SkillProficiencyModal, { SkillModalMode } from './SkillProficiencyModal/SkillProficiencyModal';
import SkillsHeader from './SkillsHeader/SkillsHeader';
import { SearchValue } from './SkillsHeader/SkillsSearch/SkillsSearch';
import { SkillsSortValue } from './SkillsHeader/SkillsSort/SkillsSort';
import { Skill, SkillWithUpdate } from './types';

type SkillModal = {
  isOpen: boolean;
  skill?: Skill;
  mode?: SkillModalMode;
};

export const errorMessage =
  'Oops, something went wrong during the fetch of your skills!';

const PersonalSkillsProfile = () => {
  const { t } = useTranslation();
  const { handleMsgType } = useNotification();
  const { data } = useSkillsProfileOpenOneQuery({
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        message: errorMessage,
      });
    },
  });

  const skills: SkillWithUpdate[] = useMemo(
    () => (data ? mapSkills(data?.SkillsProfileOpenOne.skillsWithResults) : []),
    [data]
  );

  const skillsWithoutKnowledge = skills?.filter(
    ({ proficiency }) =>
      proficiency ===
      technicalSkillProficiencyLevelToBarChartValuesMap[
        TechnicalSkillSelfAssessmentProficiencyLevel.NoKnowledge
      ]
  );

  const [skillModal, setSkillModal] = useState<SkillModal>({
    isOpen: false,
  });
  const onSkillClick = useCallback((skill: Skill) => {
    setSkillModal({
      isOpen: true,
      skill,
    });
  }, []);

  const [showNoKnowledgeSkills, setShowNoKnowledgeSkills] = useState(false);

  useEffect(() => {
    // Show "no knowledge toggle" by default if there are only "no knowledge" skills
    data &&
      skills.length === skillsWithoutKnowledge?.length &&
      setShowNoKnowledgeSkills(true);
  }, [data, skills.length, skillsWithoutKnowledge?.length]);

  const onSearchChange = useCallback(
    ({ alreadyExist, ...skill }: SearchValue) => {
      setSkillModal({
        isOpen: true,
        skill,
        mode: alreadyExist ? 'edit' : 'add',
      });
    },
    []
  );

  const [sort, setSort] = useState<SkillsSortValue>('level');

  return (
    <>
      <HelmetAndPageAnnouncer pageTitle={t('skills.personalSkillsProfile')} />
      <div className="personal-skills-profile">
        <div className="personal-skills-profile__header">
          <AppBar />
          {Boolean(skills.length) && (
            <div className="personal-skills-profile__header-update">
              {t('common.lastUpdated')}:{' '}
              {format(new Date(skills[0].updated), 'dd MMM yyyy')}
            </div>
          )}
        </div>
        <div className="personal-skills-profile__header-with-icon">
          <HeadingWithIcon
            title={t('skills.personalSkillsProfileTitle')}
            description={t('skills.personalSkillsProfileDescr')}
            icon="person"
          />
        </div>
        <div className="personal-skills-profile__skills-wrapper">
          <SkillsHeader
            sortValue={sort}
            sortDisabled={skills.length === 0}
            noKnowledgeSkillsToggleValue={showNoKnowledgeSkills}
            noKnowledgeSkillsToggleDisabled={!skillsWithoutKnowledge?.length}
            onNoKnowledgeSkillsToggle={setShowNoKnowledgeSkills}
            onSearchChange={onSearchChange}
            onSortChange={setSort}
          />
          <div className="personal-skills-profile__skills-container">
            {!data && (
              <div
                className="personal-skills-profile__loader"
                data-testid="skills-loader"
              >
                <Loader variant="bubbles" />
              </div>
            )}
            {Boolean(skills.length) && sort === 'level' && (
              <LevelLayout
                skills={skills}
                showNoKnowledgeSkills={showNoKnowledgeSkills}
                onSkillClick={onSkillClick}
              />
            )}
            {Boolean(skills.length) && sort === 'type' && (
              <TypeLayout
                skills={skills}
                showNoKnowledgeSkills={showNoKnowledgeSkills}
                onSkillClick={onSkillClick}
              />
            )}
            {Boolean(skills.length) && sort === 'lastUpdated' && (
              <LastUpdatedLayout
                skills={skills}
                showNoKnowledgeSkills={showNoKnowledgeSkills}
                onSkillClick={onSkillClick}
              />
            )}
            {data && skills.length === 0 && (
              <div
                className="personal-skills-profile__no-skills"
                data-testid="no-skills"
              >
                <Icon icon="empty_drawer" />
                <p className="personal-skills-profile__no-skills-text">
                  {t('common.noSkills')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {skillModal.isOpen && (
        <SkillProficiencyModal
          {...(skillModal as Required<SkillModal>)}
          onClose={() => setSkillModal({ isOpen: false })}
        />
      )}
    </>
  );
};

export default PersonalSkillsProfile;
