import './index.scss';
import './helpers/i18n';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Loader } from '@spotted-zebra-uk/ui-components';
import App from './App';
import { setDocumentHeadLinks } from './helpers/setDocumentHeadLinks';

setDocumentHeadLinks();
process.env.NODE_ENV === 'production' &&
  Sentry.init({
    // Optionally, configure source map handling:
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
      }),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.5,
    release: 'sz-candidate-client@2.67.0',
  });

const container = document.getElementById('app');
const root = createRoot(container as HTMLElement);
root.render(
  <Suspense fallback={<Loader variant="bubbles" />}>
    <App />
  </Suspense>
);
