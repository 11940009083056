import clsx from 'clsx';
import Icon from 'components/atoms/Icon';
import Logo from 'components/atoms/Logo';
import Container from 'components/molecules/Container/Container';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from '@spotted-zebra-uk/ui-components';
import { ThankYouPageContentType } from './ThankYouTemplate.enums';
import styles from './ThankYouTemplate.module.scss';

interface IThankYouTemplate {
  mainText: string;
  title?: string;
  loading?: boolean;
  buttonText?: string;
  contentType?: ThankYouPageContentType;
  className?: string;
  onClick: () => void;
  isPracticeTest?: boolean;
  onReturn?: () => void;
}

export const ThankYouTemplate: FC<IThankYouTemplate> = ({
  title,
  mainText,
  loading,
  buttonText,
  contentType,
  className,
  onClick,
  onReturn,
  isPracticeTest,
}) => {
  const { t } = useTranslation();
  const defaultTitle = t('stage.testCallback.testCompleted');
  const defaultButtonText = t('test.instructions.general.closeTest');

  return (
    <>
      <HelmetAndPageAnnouncer pageTitle={title || defaultTitle} />
      <Container className={clsx(styles['thank-you-template'], className)}>
        <div className={styles['thank-you-template__header']}>
          <Logo
            name="sz_logo"
            alt=""
            className={styles['thank-you-template__logo']}
          />
        </div>
        <div
          className={styles['thank-you-template__content']}
          data-content-type={contentType}
        >
          <div className={styles['thank-you-template__content-center']}>
            <Icon
              icon="claps_large"
              className={styles['thank-you-template__claps-icon']}
              title=""
            />
            <h2 className={styles['thank-you-template__title']}>
              {title || defaultTitle}
            </h2>
            <p className={styles['thank-you-template__main-text']}>
              {mainText}
            </p>
          </div>
          <div className={styles['thank-you-template__action']}>
            {loading ? (
              <Loader variant="bubbles" />
            ) : (
              <div className={styles['thank-you-template__buttons']}>
                {isPracticeTest && (
                  <Button
                    variant="secondary"
                    size="large"
                    onClick={onReturn}
                    className={styles['thank-you-template__button']}
                    data-testid="thank-you-template__return-button"
                  >
                    {t('test.instructions.general.closePracticeTest')}
                  </Button>
                )}
                <Button
                  size="large"
                  onClick={onClick}
                  className={styles['thank-you-template__button']}
                  data-testid="thank-you-template__submit-button"
                >
                  {buttonText || defaultButtonText}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
