import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Button, Loader } from '@spotted-zebra-uk/ui-components';
import { TSkillWithAnswer } from '../types';
import { SkillsListItem } from './SkillsListItem';

interface ISkillsList {
  skillsWithAnswers: TSkillWithAnswer[];
  loading: boolean;
  focusedSkillId?: number;
  hasMarkAllButton?: boolean;
  onItemClick: (skillWithAnswerId: number) => void;
  onMarkAllAsNoKnowledge: () => void;
}

export const SkillsList: FC<ISkillsList> = ({
  skillsWithAnswers,
  loading,
  focusedSkillId,
  hasMarkAllButton,
  onItemClick,
  onMarkAllAsNoKnowledge,
}) => {
  const { t } = useTranslation();
  const previouslyAnsweredSkills = skillsWithAnswers.filter(
    skillWithAnswer => skillWithAnswer.previouslyAnswered
  );
  const activeSkills = skillsWithAnswers.filter(
    skillWithAnswer => !skillWithAnswer.previouslyAnswered
  );
  const showDivider = Boolean(
    previouslyAnsweredSkills.length && activeSkills.length
  );

  return (
    <div className="skills-list" data-testid="skills-list">
      <div className="skills-list__header">
        <div className="skills-list__header-title">{t('common.skills')}</div>
        {hasMarkAllButton && (
          <Button
            className="skills-list__mark-all-button"
            variant="secondary"
            size="medium"
            onClick={onMarkAllAsNoKnowledge}
            disabled={loading}
            data-testid="mark-all-button"
            aria-label={t('assessment.markAllSkillsAsNoKnowledge')}
          >
            {t('common.markAll')}
            <BarChart value={1} className="skills-list__mark-all-chart-icon" />
          </Button>
        )}
      </div>
      {loading ? (
        <div
          className="skills-list__loader-wrapper"
          data-testid="skills-list-loader-wrapper"
        >
          <Loader variant="bubbles" />
        </div>
      ) : (
        <div
          className="skills-list__list-wrapper"
          data-testid="skills-list-list-wrapper"
        >
          {previouslyAnsweredSkills.length ? (
            <div
              className="skills-list__previously-answered-skills"
              data-testid="previously-answered-skills-list"
            >
              {previouslyAnsweredSkills.map(softSkill => (
                <SkillsListItem
                  key={softSkill.id}
                  title={softSkill.name}
                  highlighted={softSkill.id === focusedSkillId}
                  chartValue={softSkill.answerValue}
                  onClick={() => onItemClick(softSkill.id)}
                />
              ))}
            </div>
          ) : null}
          {showDivider && (
            <div
              data-testid="skills-list-divider"
              className="skills-list__divider"
            />
          )}
          {activeSkills.length ? (
            <div
              className="skills-list__active-skills"
              data-testid="previously-not-answered-skills-list"
            >
              {activeSkills.map(softSkill => (
                <SkillsListItem
                  key={softSkill.id}
                  title={softSkill.name}
                  highlighted={softSkill.id === focusedSkillId}
                  chartValue={softSkill.answerValue}
                  onClick={() => onItemClick(softSkill.id)}
                />
              ))}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
