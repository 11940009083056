import './TestInfoNote.scss';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { Tag, TagColor, TagSize } from '@spotted-zebra-uk/ui-components';

interface ITestInfoNote {
  icon: FC<{ alt: string }>;
  text: string[];
  additionalItem?: ReactNode;
  className?: string;
  alt: string;
  dataTestid?: string;
  accessibilityAdjustment?: number | null;
}

const TestInfoNote: FC<ITestInfoNote> = ({
  icon,
  text,
  additionalItem,
  className,
  alt,
  dataTestid,
  accessibilityAdjustment,
}) => {
  const NoteIcon = icon;
  return (
    <div className={clsx('TestInfoNote', className)}>
      <div className="TestInfoNote__Icon">
        <NoteIcon alt={alt} />
      </div>
      <div className="TestInfoNote__TextItem" data-testid={dataTestid}>
        <div
          className="TestInfoNote__TextContainer"
          data-testid={`${dataTestid || 'test'}-text`}
        >
          {text.map((value, index) => (
            <p key={index}>{value}</p>
          ))}
          {!!accessibilityAdjustment && (
            <div className="TestInfoNote__TextAdjustment">
              <Tag
                size={TagSize.SMALL}
                isDismissible={false}
                isPresentational={true}
                tagColor={TagColor.BLUE}
              >
                {accessibilityAdjustment}
              </Tag>
            </div>
          )}
        </div>
        {additionalItem}
      </div>
    </div>
  );
};

export default TestInfoNote;
