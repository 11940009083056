import Icon from 'components/atoms/Icon';
import { StageCandidateActionType } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';
import { questionAmount } from './PracticeTestIntro.constants';
import styles from './PracticeTestIntro.module.scss';

const PracticeTestIntro = ({
  testName,
  type,
  onStartPracticeTest,
  onStartAssessment,
  displayPracticeTestIntro,
}: {
  testName: string;
  type: StageCandidateActionType;
  onStartPracticeTest?: () => void;
  onStartAssessment?: () => void;
  displayPracticeTestIntro?: (newValue: boolean) => void;
}) => {
  const { t } = useTranslation();

  let questionCount = questionAmount[type];

  const questionWord = t(
    questionCount === 1
      ? 'common.questionOptions.question_one'
      : 'common.questionOptions.question_other'
  );

  return (
    <main className={styles.container} data-testid="practice-test-intro">
      <div className={styles.header}>
        <Button
          variant="tertiary"
          size="small"
          leftIcon={<Icon icon="arrow" />}
          aria-label={t('test.general.closePracticeTest')}
          className={styles.backButton}
          onClick={() => {
            displayPracticeTestIntro && displayPracticeTestIntro(false);
          }}
          data-testid="practice-test-intro_back-button"
        >
          {t('test.instructions.practiceTestIntro.backButton')}
        </Button>
      </div>
      <h1 className={styles.title}>
        {t('test.instructions.practiceTestIntro.title')}
      </h1>

      <section className={styles.content}>
        <h2
          className={styles.testType}
          data-testid="practice-test-intro_test-name"
        >
          {testName}
        </h2>
        <div className={styles.textContainer}>
          <p className={styles.description}>
            {t('test.instructions.practiceTestIntro.description1', {
              count: questionCount,
              questionWord,
            })}
          </p>
          <ul>
            <li>{t('test.instructions.practiceTestIntro.bulletPoint1')}</li>
            <li>{t('test.instructions.practiceTestIntro.bulletPoint2')}</li>
          </ul>
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.subTitle}>
            {t('test.instructions.practiceTestIntro.practiceTestQuestion')}
          </h3>
          <p className={styles.description}>
            {t('test.instructions.practiceTestIntro.description2')}
          </p>
          <p className={styles.description}>
            {t('test.instructions.practiceTestIntro.description3')}
          </p>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            variant="secondary"
            aria-label={t('test.startTestName', { testTitle: testName })}
            onClick={onStartAssessment}
            data-testid="practice-test-intro_start-assessment-button"
          >
            {t('test.instructions.general.startNow')}
          </Button>
          <Button
            variant="primary"
            aria-label={t('test.practice', { testTitle: testName })}
            onClick={onStartPracticeTest}
            data-testid="practice-test-intro_start-practice-test-button"
          >
            {t('test.instructions.practiceTestIntro.cta')}
          </Button>
        </div>
      </section>
    </main>
  );
};

export default PracticeTestIntro;
